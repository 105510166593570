<template>
  <span>
    <b-sidebar
      sidebar-class="big_sidebar create-other-message"
      id="add-meeting"
      v-model="show"
      right
      backdrop
      no-slide
      lazy
      shadow
      no-header-close
      no-close-on-backdrop
      no-close-on-esc
      @shown="shownSidebar"
      @hidden="closeBtn"
      v-if="CreateMeetingDTO"
    >
      <template #header="{ hide }">
        <b-row class="w-100" no-gutters>
          <b-col cols-md="6">
            <strong class="ml-2 mr-2" v-if="GetTitle">{{ GetTitle }}</strong>
          </b-col>
          <b-col cols-md="6">
            <b-alert
              show
              variant="danger"
              class="small-text mb-0 p-1"
              v-if="!changeTime && !IsDraft"
            >
              {{ $t("CHANGE_MEETING_INFORMATION_SERIE") }}
            </b-alert>
          </b-col>
        </b-row>
      </template>

      <template #footer="{ hide }">
        <div v-if="changeAttachments">
          <div v-show="step == 1" class="ml-2">
            <b-button @click="closeBtn">{{ $t("CLOSE") }}</b-button>
          </div>
        </div>

        <div v-if="!changeAttachments">
          <div v-show="step == 1" class="ml-2">
            <b-button :disabled="saving" @click="closeBtn">{{
              $t("CLOSE")
            }}</b-button>
            <b-button
              v-if="IsDraft"
              @click="saveMeeting(true)"
              :disabled="saving || disabledSave"
              variant="secondary"
              class="btn-fill"
              ><span v-if="!saving">{{ $t("SAVE_DRAFT") }}</span
              ><span v-if="saving">{{ $t("SAVING") }}..</span></b-button
            >

            <b-button
              v-if="!changeTime"
              :disabled="disabledStep2"
              @click="step = 2"
              variant="primary"
              class="btn-fill"
              >{{ $t("NEXT") }}</b-button
            >

            <b-button
              :disabled="saving || disabledSend"
              v-if="changeTime"
              @click="UpdateBtn"
              variant="primary"
              class="btn-fill"
              >{{ $t("UPDATE") }}</b-button
            >

            <b-button
              v-if="CreateMeetingDTO.message_uuid != '' && IsDraft"
              @click="RemoveDraft"
              :disabled="saving"
              variant="danger"
              class="btn-fill float-right"
              >{{ $t("REMOVE") }}</b-button
            >
          </div>
          <div v-show="step == 2" class="ml-2">
            <b-button @click="step = 1">{{ $t("BACK") }}</b-button>

            <b-button
              v-if="IsDraft"
              @click="saveMeeting(true)"
              :disabled="saving || disabledSave"
              variant="secondary"
              class="btn-fill"
              ><span v-if="!saving">{{ $t("SAVE_DRAFT") }}</span
              ><span v-if="saving">{{ $t("SAVING") }}..</span></b-button
            >

            <b-button
              :disabled="disabledStep3"
              @click="step = 3"
              variant="primary"
              class="btn-fill"
              >{{ $t("NEXT") }}</b-button
            >

            <b-button
              v-if="
                (CreateMeetingDTO.message_uuid != '' || message_uuid != '') &&
                IsDraft
              "
              @click="RemoveDraft"
              :disabled="saving"
              variant="danger"
              class="btn-fill float-right"
              >{{ $t("REMOVE") }}</b-button
            >
          </div>
          <div v-show="step == 3" class="ml-2">
            <b-button @click="step = 2">{{ $t("BACK") }}</b-button>

            <b-button
              v-if="IsDraft"
              @click="saveMeeting(true)"
              :disabled="saving || disabledSave"
              variant="secondary"
              class="btn-fill"
              ><span v-if="!saving">{{ $t("SAVE_DRAFT") }}</span
              ><span v-if="saving">{{ $t("SAVING") }}..</span></b-button
            >

            <b-button @click="step = 4" variant="primary" class="btn-fill">{{
              $t("NEXT")
            }}</b-button>
            <b-button
              v-if="
                (CreateMeetingDTO.message_uuid != '' || message_uuid != '') &&
                !isChange
              "
              @click="RemoveDraft"
              :disabled="saving"
              variant="danger"
              class="btn-fill float-right"
              >{{ $t("REMOVE") }}</b-button
            >
          </div>
          <div v-show="step == 4" class="ml-2">
            <b-button @click="step = 3">{{ $t("BACK") }}</b-button>

            <b-button
              v-if="IsDraft"
              @click="saveMeeting(true)"
              :disabled="saving || disabledSave"
              variant="secondary"
              class="btn-fill"
              ><span v-if="!saving">{{ $t("SAVE_DRAFT") }}</span
              ><span v-if="saving">{{ $t("SAVING") }}..</span></b-button
            >

            <b-button
              :disabled="saving || disabledSend"
              v-if="IsDraft"
              @click="saveMeeting(false)"
              variant="primary"
              class="btn-fill"
              >{{ $t("SUBMIT") }}</b-button
            >

            <b-button
              :disabled="saving || disabledSend"
              @click="UpdateBtn"
              v-if="!IsDraft"
              variant="primary"
              class="btn-fill"
              >{{ $t("UPDATE") }}</b-button
            >

            <b-button
              v-if="
                (CreateMeetingDTO.message_uuid != '' || message_uuid != '') &&
                IsDraft
              "
              @click="RemoveDraft"
              :disabled="saving"
              variant="danger"
              class="btn-fill float-right"
              >{{ $t("REMOVE") }}</b-button
            >
          </div>
        </div>
      </template>
      <b-overlay :show="!CreateMeetingDTO.isDraft && saving" rounded="sm">
        <div class="pb-4" v-if="loaded">
          <div v-if="settings" class="px-3 py-2 view-content create-message">
            <b-alert
              dismissible
              v-if="
                CreateMeetingDTO.settings.loa_level != 3 &&
                !IsDraft &&
                !changeTime
              "
              show
              variant="warning"
              class="small-text pl-1 pt-1 pb-1 pr-1"
            >
              {{
                $t("CHANGE_MEETING_LOA", {
                  loa_level: CreateMeetingDTO.settings.loa_level,
                })
              }}
              <InputLoa
                bold
                :loa="CreateMeetingDTO.settings.loa_level"
              ></InputLoa>
              <br />{{ $t("CHANGE_MEETING_WARNING1") }}
              <div v-if="CreateMeetingDTO.settings.loa_level < 3">
                <i class="fal fa-circle-small"></i>&nbsp;&nbsp;
                <InputLoa :loa="3" show-info></InputLoa>
              </div>
              <div v-if="CreateMeetingDTO.settings.loa_level < 2">
                <i class="fal fa-circle-small"></i>&nbsp;&nbsp;
                <InputLoa :loa="2" show-info></InputLoa>
              </div>
              {{ $t("CHANGE_MEETING_WARNING2") }}
            </b-alert>

            <div v-show="step == 1">
              <div
                v-if="
                  IsDraft || (isChange && !changeTime && !changeAttachments)
                "
              >
                <InputSubject
                  ref="InputSubject"
                  v-model="CreateMeetingDTO.subject"
                  :settings="settings"
                  @valid="(n) => (validatedSubject = n)"
                  :limit="256"
                ></InputSubject>

                <div class="mt-2">{{ $t("ADD_RECIPIENTS") }}</div>

                <InputParticipants
                  :message_uuid="CreateMeetingDTO.message_uuid"
                  :key="'InputParticipants_' + externalParticipantsKey"
                  message_type="jitsi-secure-meeting"
                  is-meeting
                  :participants="CreateMeetingDTO.participants"
                  :external_participants="
                    CreateMeetingDTO.external_participants
                  "
                  @IsTyping="IsTyping"
                  @UpdatedParticipants="UpdatedParticipants"
                ></InputParticipants>

              </div>

              <div v-if="changeTime && recurrence">
                <b-form-group
                  :label="$t('UPDATE')"
                  label-cols-md="2"
                  class="mb-2"
                >
                  <b-form-radio
                    @change="ChangedEditRecurring"
                    v-model="editRecurring"
                    name="edit-recurring-single"
                    value="SINGLE"
                    >{{ $t("EDIT_RECURRENCE_SINGLE") }}</b-form-radio
                  >
                  <b-form-radio
                    @change="ChangedEditRecurring"
                    v-model="editRecurring"
                    name="edit-recurring-multiple"
                    value="MULTIPLE"
                    >{{ $t("EDIT_RECURRENCE_MULTIPLE") }}</b-form-radio
                  >
                  <b-form-radio
                    @change="ChangedEditRecurring"
                    v-model="editRecurring"
                    name="edit-recurring-all"
                    value="ALL"
                    >{{ $t("EDIT_RECURRENCE_ALL") }}</b-form-radio
                  >
                </b-form-group>
              </div>

              <div v-if="IsDraft">
                <div class="mt-2">{{ $t("TIME") }}</div>
              </div>

              <div v-if="changeTime || IsDraft">
                <b-alert
                  show
                  variant="warning"
                  class="pl-1 pt-1 pb-1 pr-1"
                  v-if="IsDraft && eventExpired"
                >
                  {{ $t("EVENT_EXPIRED") }}
                </b-alert>

                <PickDate
                  v-model="CreateMeetingDTO.settings.meeting_start_date"
                  :use-label="$t('FROM')"
                  use-clock
                  is-meeting
                  :disable-date="changeTime && editRecurring == 'MULTIPLE'"
                  @valid="(n) => (validMeetingStart = n)"
                ></PickDate>

                <PickDate
                  :start-date="CreateMeetingDTO.settings.meeting_start_date"
                  v-model="CreateMeetingDTO.settings.meeting_end_date"
                  :use-label="$t('TO')"
                  use-clock
                  :disable-date="changeTime && editRecurring == 'MULTIPLE'"
                  @valid="(n) => (validMeetingEnd = n)"
                ></PickDate>

                <div v-if="startDifference">
                  <div class="error-text mb-2" v-if="startDifference.days != 0">
                    {{
                      $t("ERROR.MEETING_SPANS_DAYS", {
                        days: startDifference.days,
                      })
                    }}
                  </div>
                </div>

                <PickRecurrence
                  :disabled="disableRecurrence"
                  v-model="CreateMeetingDTO.recurrence"
                  @valid="(n) => (validRecurrence = n)"
                  @changed="() => (recurrenceIndex += 1)"
                  :serieStartDate="serieStartDate"
                  :startDate="CreateMeetingDTO.settings.meeting_start_date"
                  :endDate="CreateMeetingDTO.settings.meeting_end_date"
                ></PickRecurrence>

                <PickRecurrence
                  :key="'recurrenceIndex_' + recurrenceIndex"
                  :recurrence="CreateMeetingDTO.recurrence"
                  :serieStartDate="serieStartDate"
                  :startDate="CreateMeetingDTO.settings.meeting_start_date"
                  :endDate="CreateMeetingDTO.settings.meeting_end_date"
                  text-only
                ></PickRecurrence>
              </div>

              <div v-if="!changeTime && !changeAttachments">

              <div class="mt-2">
                <i class="fa-light fa-lock-keyhole-open"></i>&nbsp;{{ $t("INVITATION_INFORMATION") }}
              </div>

              <InputExternalText
                ref="InputExternalText"
                v-model="CreateMeetingDTO.external_text"
                @valid="(n) => (validatedExternalText = n)"
                :settings="settings"
                :rows="5"
                is-meeting
                :limit="1500"
              ></InputExternalText>

              </div>
              
            </div>

            <div v-show="step == 2">

              <div class="mt-2"><i class="fa-light fa-lock-keyhole"></i>&nbsp;{{ $t("MEETING_NOTES") }}</div>

              <InputBody
                ref="InputBody"
                v-model="CreateMeetingDTO.body"
                :settings="settings"
                @valid="(n) => (validatedBody = n)"
                :limit="20000"
                :rows="8"
              ></InputBody>
            </div>

            <div v-if="(step == 2 && IsDraft) || changeAttachments">

              <b-alert v-if="changeAttachments" show variant="secondary" class="mb-0 p-1">{{ $t("ENCRYPTED_BODY") }}</b-alert>

              <div class="mt-2">{{ $t("ADD_FILES") }}</div>

              <div :key="'AddFiles' + CreateMeetingDTO.message_uuid">
                <InputFiles
                  :message_uuid="CreateMeetingDTO.message_uuid"
                  v-model="CreateMeetingDTO.attachments"
                  :show-warning="changeAttachments"
                  :cannot-upload="$t('DRAFT.SEFOS_UPLOAD')"
                  :max-size="200"
                  @valid="(n) => (validatedFiles = n)"
                ></InputFiles>
              </div>
            </div>

            <div v-show="step == 3">
              <div class="mt-2">{{ $t("MESSAGES.LOA_LEVELS") }}</div>

              <InputLoa
                v-model="CreateMeetingDTO.settings.loa_level"
              ></InputLoa>
            </div>

            <div v-show="step == 4">
              <div class="mt-2">
                {{ $t("MESSAGES.NOT_REGISTERED_INFORMATION") }}
              </div>

              <b-alert
                show
                variant="danger"
                class="small-text mb-0 p-1"
                v-if="!IsDraft && recurrence && (step == 1 || step == 4)"
              >
                {{ $t("CHANGE_MEETING_RECIPIENTS") }}
              </b-alert>

              <div
                class="mt-2"
                :key="
                  'NotRegistedLoa_' +
                  CreateMeetingDTO.settings.loa_level +
                  '_' +
                  CreateMeetingDTO.external_participants
                "
              >
                <b-alert
                  show
                  variant="warning"
                  class="small-text mb-0 p-1"
                  v-if="CreateMeetingDTO.external_participants.length == 0"
                  >{{ $t("NO_RECIPIENTS") }}</b-alert
                >
                <div
                  v-for="(
                    item, index
                  ) in CreateMeetingDTO.external_participants"
                  :key="'NotRegistered_' + index"
                  class="NotRegistered"
                >
                  <NotRegistered
                    @change="UpdatedNotRegistered"
                    v-model="CreateMeetingDTO.external_participants[index]"
                    @remove="RemoveNotRegistered(index)"
                    message_type="message"
                    :methods="methods"
                    :showSefos="item.showSefos"
                    :loa_level="CreateMeetingDTO.settings.loa_level"
                  ></NotRegistered>
                </div>
              </div>
              <b-button @click="step = 1" variant="secondary" class="mt-2">{{
                $t("ADD_MORE_PARTICIPANTS")
              }}</b-button>
            </div>
          </div>
        </div>
      </b-overlay>
    </b-sidebar>

    <b-modal
      id="show-accept-alot-users"
      v-model="ShowAcceptAlotUsers"
      hide-header
      @cancel="CancelAcceptAlotUsers"
      @ok="OkAcceptAlotUsers"
      centered
      :key="'AcceptAlotUsers' + CreateMeetingDTO.external_participants.length"
      :cancel-title="$t('NO')"
      :ok-title="$t('YES')"
    >
      {{ $t('SEND_ALOT_OF_USERS',  { amount: CreateMeetingDTO.external_participants.length  } ) }}
    </b-modal>
  </span>
</template>
<script>
import { DateTime } from "luxon";
import InputSubject from "../../Input/Subject";
import InputBody from "../../Input/Body";
import InputExternalText from "../../Input/ExternalText";
import InputParticipants from "../../Input/Participants";
import InputFiles from "../../Input/Files";
import NotRegistered from "../../Compose/NotRegistered";
import PickDate from "../../Input/PickDate";
import PickRecurrence from "../../Input/PickRecurrence";
import InputLoa from "../../Input/Loa";
export default {
  props: {
    isChange: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    changeTime: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    changeAttachments: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    show: {
      default: false,
      type: Boolean,
    },
    message_uuid: {
      default: "",
      type: String,
    },
    startMeeting: {
      default: "",
      type: String,
    },
    endMeeting: {
      default: "",
      type: String,
    },
  },
  components: {
    InputSubject,
    InputBody,
    InputExternalText,
    InputParticipants,
    InputFiles,
    NotRegistered,
    PickDate,
    PickRecurrence,
    InputLoa,
  },
  data() {
    return {
      ShowAcceptAlotUsers: false,
      AcceptAlotUsers: true,
      editRecurring: "SINGLE",
      showRecurringOptions: false,
      externalParticipantsKey: 0,
      recurrenceIndex: 0,
      validRecurrence: true,
      validMeetingStart: true,
      validMeetingEnd: true,
      validatedSubject: true,
      validatedBody: true,
      validatedExternalText: true,
      saving: false,
      isTyping: false,
      timeout: 2000,
      canSave: false,
      loaded: false,
      timer: null,
      validatedFiles: true,
      OriginalDTO: null,
      CreateMeetingDTO: {
        join_url: "",
        boardId: "",
        recurrence: null,
        user_uuid: "",
        functionbox_uuid: "",
        action: "INIT",
        message_uuid: "",
        conversation_uuid: "",
        subject: "",
        body: "",
        attachments: [],
        participants: [],
        external_text: "",
        external_participants: [],
        settings: {
          loa_level: 1,
          open_to_date: null,
          open_from_date: null,
          require_response: 1,
          meeting_start_date: "",
          meeting_end_date: "",
        },
        custom_identifier: "",
        client: "web",
        response_to_message_uuid: this.message_uuid,
        webhook: "",
        isDraft: true,
      },
      settings: null,
      forceRedrawRecipientsIndex: 0,
      step: 1,
      methods: [],
      loading: false,
      isDateTimeValid: false,
      recurrence: null,
      serieStartDate: "",
    };
  },
  methods: {
    OkAcceptAlotUsers(){
      this.ShowAcceptAlotUsers = false;
      this.AcceptAlotUsers = true;
    },
    CancelAcceptAlotUsers(){
      this.ShowAcceptAlotUsers = false;
    },
    ChangedEditRecurring() {
      this.GetInformation();
    },
    RemoveNotRegistered(index) {
      this.CreateMeetingDTO.external_participants.splice(index, 1);
      this.externalParticipantsKey++;
    },
    async RemoveDraft() {
      this.loading = true;
      let self = this;
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            self.$http
              .post(self.user.hostname + "/meetings/secure/remove", {
                message_uuid:
                  self.CreateMeetingDTO.message_uuid == ""
                    ? self.message_uuid
                    : self.CreateMeetingDTO.message_uuid,
                user_uuid: "",
                functionbox_uuid: "",
              })
              .then(() => {
                self.loading = false;
                self.$noty.info(self.$t("REMOVED"));
                self.closeBtn();
              })
              .catch(() => {
                self.loading = false;
              });
          }
        })
        .catch(function () {});
    },
    UpdatedParticipants(updated) {
      this.CreateMeetingDTO.participants = updated.participants;
      this.CreateMeetingDTO.external_participants = updated.external_participants;
    },
    debounceSave() {
      if (!this.isChange) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.saveMeeting(true);
        }, this.timeout);
      }
    },
    UpdatedNotRegistered() {
      this.debounceSave();
    },
    IsTyping(val) {
      this.isTyping = val;
    },
    async closeBtn() {
      this.$emit("ChangeShow", false);
    },
    async shownSidebar() {
      await this.$store.dispatch("user/fetchUser");
      this.settings = await this.GetSettings();
      this.methods = await this.GetMethods();
      if (this.settings && this.methods) {
        if (this.message_uuid != "") {
          await this.GetInformation();
        } else {
          this.InitSettings();
        }
      } else {
        console.error("Failed to load settings or methods");
      }
    },
    async InitSettings() {

      if (this.user.information.signature != "") {
        this.CreateMeetingDTO.body =
          "\n\n----------------------------\n" +
          this.user.information.signature;
      }
      if (this.settings.subject != "") {
        this.CreateMeetingDTO.subject = this.settings.subject;
      }
      if (this.settings.email_body != "") {
        this.CreateMeetingDTO.external_text = this.settings.email_body;
      }
      if(this.user.loa_level_enabled.loa1_enabled == 0){
        this.CreateMeetingDTO.settings.loa_level = 2;
        if(this.user.loa_level_enabled.loa2_enabled == 0){
          this.CreateMeetingDTO.settings.loa_level = 3;
        }
      }      
      this.InitDone();
    },
    InitDone() {
      this.loading = false;
      this.loaded = true;
      setTimeout(() => {
        this.canSave = true;
      }, this.timeout);
      this.CreateMeetingDTO.action = "NEW";
    },
    async GetSettings() {
      this.loading = true;
      let result = await this.$http.post(this.user.hostname + "/settings/get", {
        setting: "JitsiSecure",
        organisation_uuid: this.user.information.organisation.organisation_uuid,
        functionbox_uuid: "",
      });
      return result.data;
    },
    async GetInformation() {
      try {
        let result = await this.$http.get(
          this.user.hostname + "/meetings/info/" + this.message_uuid
        );
        this.CreateMeetingDTO = result.data;
        this.OriginalDTO = JSON.parse(JSON.stringify(result.data));

        this.recurrence = result.data.recurrence;
        this.serieStartDate =
          this.CreateMeetingDTO.settings.meeting_start_date + "";
        if (this.startMeeting != null && this.startMeeting != "") {
          this.CreateMeetingDTO.settings.meeting_start_date = this.startMeeting;
        }
        if (this.endMeeting != null && this.endMeeting != "") {
          this.CreateMeetingDTO.settings.meeting_end_date = this.endMeeting;
        }
        this.InitDone();
        this.loaded = true;
      } catch {
        console.log("Could not GetInformation");
      }
    },
    async GetMethods() {
      let result = await this.$http.get(
        this.user.hostname + "/authenticate/external/methods"
      );
      return result.data;
    },
    async UpdateBtn() {
      this.UpdateMeeting();
    },
    async UpdateMeeting() {
      this.loading = true;
      this.saving = true;
      try {
        let UpdateMeetingDTO = {
          messageUuid: this.OriginalDTO.message_uuid,
          currentStart:
            this.startMeeting == null || this.startMeeting == ""
              ? this.OriginalDTO.settings.meeting_start_date
              : this.startMeeting,
          currentEnd:
            this.endMeeting == null || this.endMeeting == ""
              ? this.OriginalDTO.settings.meeting_end_date
              : this.endMeeting,
          newStart: null,
          newEnd: null,
          newLocation: null,
          affectSubsequent: this.editRecurring == "MULTIPLE" ? true : false,
          affectAll: this.editRecurring == "ALL" ? true : false,
          externalText:
            this.CreateMeetingDTO.external_text !=
            this.OriginalDTO.external_text
              ? this.CreateMeetingDTO.external_text
              : null,
          externalParticipants: this.CreateMeetingDTO.external_participants,
          loa_level: this.CreateMeetingDTO.settings.loa_level,
          recurrence: this.CreateMeetingDTO.recurrence,
          subject:
            this.CreateMeetingDTO.subject != this.OriginalDTO.subject
              ? this.CreateMeetingDTO.subject
              : null,
          body:
            this.CreateMeetingDTO.body != this.OriginalDTO.body
              ? this.CreateMeetingDTO.body
              : null,
        };
        UpdateMeetingDTO.newStart =
          UpdateMeetingDTO.currentStart + "" !=
          this.CreateMeetingDTO.settings.meeting_start_date + ""
            ? this.CreateMeetingDTO.settings.meeting_start_date
            : null;
        UpdateMeetingDTO.newEnd =
          UpdateMeetingDTO.currentEnd + "" !=
          this.CreateMeetingDTO.settings.meeting_end_date + ""
            ? this.CreateMeetingDTO.settings.meeting_end_date
            : null;

        await this.$http.post(
          this.user.hostname + "/meetings/update",
          UpdateMeetingDTO
        );
        this.$noty.info(this.$t("CHANGED_MEETING"));
        this.loading = false;
        this.saving = false;
        this.$emit("SentMeeting");
        this.closeBtn();
      } catch (error) {
        this.showRecurringOptions = false;
        this.saving = false;
        this.loading = false;
      }
    },
    async saveMeeting(isDraft) {
      if (
        this.saving ||
        this.isTyping ||
        this.disabledSave ||
        this.isChange ||
        this.changeTime ||
        this.changeAttachments
      ) {
        return;
      }
      this.saving = true;
      clearTimeout(this.timer);
      this.loading = true;
      this.CreateMeetingDTO.isDraft = isDraft;

      if (
        !this.CreateMeetingDTO.recurrence ||
        this.CreateMeetingDTO.recurrence.frequency === "None"
      ) {
        this.CreateMeetingDTO.recurrence = null;
      }

      try {
        let result = await this.$http.post(
          this.user.hostname + "/jitsi/confidentially/secure",
          this.CreateMeetingDTO
        );
        this.loading = false;
        if (isDraft) {
          this.CreateMeetingDTO.message_uuid = result.data.message_uuid;
          this.saving = false;
        } else {
          if (this.isChange) {
            if (result.data.all_sent != undefined) {
              if (result.data.all_sent) {
                this.$noty.info(this.$t("CHANGED_MEETING"));
              } else {
                this.$noty.warning(this.$t("CHANGED_MEETING_NOT_SENT"));
              }
            } else {
              this.$noty.info(this.$t("CHANGED_MEETING"));
            }
          } else {
            if (result.data.all_sent != undefined) {
              if (result.data.all_sent) {
                this.$noty.info(this.$t("SENT_MEETING"));
              } else {
                this.$noty.warning(this.$t("SENT_MEETING_NOT_SENT"));
              }
            } else {
              this.$noty.info(this.$t("SENT_MEETING"));
            }
          }
          this.$emit("SentMeeting");
          this.closeBtn();
        }
      } catch (error) {
        this.saving = false;
        this.loading = false;
      }
    },
  },
  computed: {
    startDifference() {
      if (
        this.CreateMeetingDTO.settings.meeting_start_date != "" &&
        this.CreateMeetingDTO.settings.meeting_end_date != ""
      ) {
        const startDate = DateTime.fromISO(
          this.CreateMeetingDTO.settings.meeting_start_date,
          { zone: "utc" }
        );
        const endDate = DateTime.fromISO(
          this.CreateMeetingDTO.settings.meeting_end_date,
          { zone: "utc" }
        );
        const diff = endDate.diff(startDate, [
          "years",
          "months",
          "days",
          "hours",
          "minutes",
        ]);
        return diff;
      }
      return null;
    },
    eventExpired() {
      let now = new DateTime.local().toUTC().toISO();
      return now > this.CreateMeetingDTO.settings.meeting_end_date;
    },
    IsDraft() {
      return !this.isChange && !this.changeAttachments && !this.changeTime;
    },
    disabledSave() {
      return (
        !this.validatedBody ||
        !this.validatedExternalText ||
        !this.validatedSubject ||
        !this.validatedFiles ||
        !this.validMeetingStart ||
        !this.validMeetingEnd ||
        !this.validRecurrence
      );
    },
    disabledStep2() {
      if (this.CreateMeetingDTO.subject.length >= 256) {
        return true;
      }
      if (!this.validMeetingStart) {
        return true;
      }
      if (!this.validMeetingEnd) {
        return true;
      }
      return false;
    },
    disabledStep3() {
      if (this.disabledStep2) {
        return true;
      }
      if (this.CreateMeetingDTO.body.length >= 20000) {
        return true;
      }
      if (!this.validatedFiles) {
        return true;
      }
      return false;
    },
    disableRecurrence() {
      if (!this.changeTime) {
        return false;
      }
      if (this.recurrence != null) {
        return false;
      }
      if (this.editRecurring != "MULTIPLE") {
        return false;
      }
      return true;
    },
    disabledSend() {
      if(!this.AcceptAlotUsers)
      {
        return true;
      }
      if (this.disabledSave) {
        return true;
      }
      if (this.disabledStep3) {
        return true;
      }
      if (this.CreateMeetingDTO.subject.length == "") {
        return true;
      }
      if (this.CreateMeetingDTO.external_participants.length == 0) {
        return true;
      }
      let allValidated =
        this.CreateMeetingDTO.external_participants.filter(function (
          searchItem
        ) {
          return searchItem.validated == true;
        }).length == this.CreateMeetingDTO.external_participants.length;
      if (!allValidated) {
        return true;
      }
      return false;
    },
    GetTitle() {
      if (this.changeAttachments) {
        return this.$t("CHANGE_MEETING_FILES");
      }
      if (this.changeTime) {
        return this.$t("CHANGE_TIME");
      }
      if (this.saving) {
        return `${this.$t("MESSAGES.TYPE.jitsi-secure-meeting")} (${
          this.CreateMeetingDTO.isDraft ? this.$t("SAVING") : this.$t("SENDING")
        } .. )`;
      }
      return this.$t("MESSAGES.TYPE.jitsi-secure-meeting");
    },
  },
  watch: {
    "CreateMeetingDTO.body": function () {
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateMeetingDTO.recurrence": function () {
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateMeetingDTO.external_participants": function (newVal, oldVal) {      
      if (this.CreateMeetingDTO.action != "INIT")
      {
        if(newVal.length > oldVal.length && newVal.length > 19 )
        {
          this.AcceptAlotUsers = false;
          this.ShowAcceptAlotUsers = true;
        }
      }      
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        let allValidated = this.CreateMeetingDTO.external_participants.filter(
          function (searchItem) {
            return (
              searchItem.validated == true ||
              searchItem.authentication_method == "NOT_SET"
            );
          }
        );
        if (
          allValidated.length ==
          this.CreateMeetingDTO.external_participants.length
        ) {
          this.debounceSave();
        }
      }
    },
    "CreateMeetingDTO.external_text": function () {
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateMeetingDTO.settings.loa_level": function () {
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateMeetingDTO.settings.meeting_start_date": function (newStartDate) {
      if (this.CreateMeetingDTO && this.CreateMeetingDTO.settings) {
        this.CreateMeetingDTO.settings.meeting_start_date = newStartDate;
      }
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateMeetingDTO.settings.meeting_end_date": function () {
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateMeetingDTO.subject": function () {
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
  },
};
</script>
